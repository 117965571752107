import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Switch, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { CheckApp, GlobleValues } from "./App"

const SearchForm = ({ queryList, clickAdd }) => {
    return <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={6}>
            <Col xs={24} sm={24} md={20} lg={18} xl={12}>
                <Space>
                    <Form.Item name="dn">
                        <Input placeholder="名称/ID模糊查询" allowClear className='input1' />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={clickAdd} >
                            添加设备
                        </Button>
                    </Form.Item>
                </Space>
            </Col>
        </Row>
    </Form>
};

const { confirm } = Modal;

export default function Device() {

    const { app } = useParams();
    const p404 = CheckApp()
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const { makeRequest } = GlobleValues()
    const [deviceForm] = Form.useForm();
    const [id, setId] = useState(0);

    const queryList = useCallback((values) => {
        let dn = values['dn'] ? values['dn'] : '';
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices?dn=${dn}`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Device`;
            queryList({})
        }
    }, [p404, queryList]);

    if (p404) {
        return p404
    }

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const showPromiseConfirm = (id) => {
        confirm({
            title: '确定删除这个设备?',
            icon: <ExclamationCircleOutlined />,
            content: '从设备列表删除（玩家数据不会删除）',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                setLd(true)
                makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${id}`, null, delOk, setLd)
            },
            onCancel() { },
        });
    }

    const clickAdd = () => {
        deviceForm.resetFields();
        setOpen(true);
        setId(0);
    }
    const clickModify = (r) => {
        deviceForm.setFieldsValue({
            device_id: r.device_id,
            device_name: r.device_name,
            beta: r.beta,
        })
        setOpen(true);
        setId(r.id)
    }

    const okfn = () => {
        setOpen(false);
        queryList({});
    }
    const createDev = (values) => {
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices`, values, okfn, setLd)
    };

    const updateDev = (values) => {
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${id}`, values, okfn, setLd)
    }

    const betaOk = (changed) => {
        setDs(ds => ds.map(item => item.id === changed.id ? changed : item))
    }
    const beta = (r) => {
        setLd(true)
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${r.id}`, r, betaOk, setLd)
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'device_name',
            width: 136,
        },
        {
            title: '设备ID',
            dataIndex: 'device_id',
            width: 136,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 128,
        },
        {
            title: '操作',
            width: 224,
            render: (_, r) => {
                return <>
                    <Row gutter={8} justify="start">
                        <Col >
                            <Switch checkedChildren="test" checked={r.beta} onChange={b => {
                                r.Beta = b; beta(r)
                            }} />
                        </Col>
                        <Col>
                            <Button type="dashed" onClick={() => clickModify(r)}>
                                修改
                            </Button>
                        </Col>
                        <Col>
                            <Button type="dashed" danger
                                onClick={() => showPromiseConfirm(r.id)}
                            >
                                删除
                            </Button>
                        </Col>
                    </Row>
                </>
            },
        },
    ]

    return (
        <>
            <SearchForm app={app} queryList={queryList} clickAdd={clickAdd} />

            <Modal
                // title="设备信息"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    deviceForm.submit()
                }}
            >
                <Form
                    form={deviceForm}
                    layout="vertical"
                    onFinish={(values) => {
                        if (id > 0) {
                            updateDev(values)
                        } else {
                            createDev(values)
                        }
                    }}
                >
                    <Form.Item
                        label="设备名称"
                        name="device_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入设备名称！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="设备ID"
                        name="device_id"
                        rules={[
                            {
                                required: true,
                                message: '请输入设备ID！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="测试设备"
                        name="beta"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="test" />
                    </Form.Item>
                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
