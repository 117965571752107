import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, InputNumber, Space } from 'antd';
import { useParams } from "react-router-dom";
import { CheckApp, GlobleValues } from "./App"

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={24}>
            <Col xs={24} sm={24} md={20} lg={18} xl={12}>
                <Space>
                    <Form.Item name="dn">
                        <Input placeholder="名称模糊查询" allowClear className='input1' />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button type="primary" onClick={clickAdd} >
                                添加活动
                            </Button>
                        </Space>
                    </Form.Item>
                </Space>
            </Col>
        </Row>
    </Form>
};

export default function EventDef() {

    const { app } = useParams();
    const p404 = CheckApp();
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const { makeRequest } = GlobleValues()
    const [eventForm] = Form.useForm();

    const queryList = useCallback((values) => {
        let dn = values['dn'] ? values['dn'] : '';
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events?dn=${dn}`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Event Def`;
            queryList({})
        }
    }, [p404, queryList]);

    if (p404) {
        return p404
    }

    const clickAdd = () => {
        eventForm.resetFields();
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({});
    }
    const newEvent = (values) => {
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events`, values, okfn, setLd)
    };

    const columns = [
        {
            title: '活动名称',
            dataIndex: 'event_name',
            width: 136,
        },
        {
            title: '值',
            dataIndex: 'event_id',
            width: 136,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 128,
        },
    ]

    return (
        <>
            <SearchForm app={app} queryList={queryList} clickAdd={clickAdd} />

            <Modal
                // title="设备信息"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    eventForm.submit()
                }}
            >
                <Form
                    form={eventForm}
                    layout="vertical"
                    onFinish={(values) => {
                        newEvent(values)
                    }}
                >
                    <Form.Item
                        label="活动名称"
                        name="event_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入活动名称！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="值"
                        name="event_id"
                        rules={[
                            {
                                required: true,
                                message: '请输入活动id！',
                            },
                        ]}
                    >
                        <InputNumber min={0} step={1} precision={0} />
                    </Form.Item>
                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
