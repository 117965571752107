import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Breadcrumb, Result, Button, theme } from 'antd';
import { LaptopOutlined, BranchesOutlined, GroupOutlined, UserOutlined } from '@ant-design/icons';
import { Link, Routes, Route, BrowserRouter as Router, useLocation } from "react-router-dom";

import Apps from './AppList';
import AppMenu from './AppMenu';
import AppHome from './AppHome';
import Device from './Device';
import Cert from './Cert';
import Oplog from './Oplog';
import Skad from './Skad';
import Data from './Data';
import Mapps from './Mapps';
import Mapp from './Mapp';
import Txt from './Txt';
import Res from './Res';
import Version from './Version';
import VersionRes from './VersionRes';
import Ab from './Ab';
import Treasure from './Treasure';
import EventDef from './EventDef';
import EventPlan from './EventPlan';
import Acquire from './Acquire';
import { CheckApp, GlobleValues } from "./App"

const { Header, Content, Footer, Sider } = Layout;

const P404 = () => {
    return <Result
        status="404"
        title="404"
        subTitle="抱歉，这个页面暂时不存在。"
        extra={<Button type="primary"><Link to={`/modp`} >Back Home</Link></Button>}
    />
}

const modpReg = new RegExp(`^/modp(/.*)?$`);
const otherReg = new RegExp(`^/modp/(cert|oplog|skad|menu)(/.*)?$`);

const MenuTop = () => {
    const location = useLocation();
    let keys = [];
    if (otherReg.test(location.pathname)) {
        keys = [location.pathname.split('/')[2]];
    } else if (modpReg.test(location.pathname)) {
        keys = ['modp'];
    }
    const items = [
        {
            key: "modp",
            label: <Link to={`/modp`} >home</Link>
        },
        {
            key: `cert`,
            label: <Link to={`/modp/cert`} >cert</Link>,
        },
        {
            key: `oplog`,
            label: <Link to={`/modp/oplog`} >oplog</Link>,
        },
        {
            key: `skad`,
            label: <Link to={`/modp/skad`} >skad</Link>,
        },
        {
            key: `menu`,
            label: <Link to={`/modp/menu`} >menu</Link>,
        },
        // , {
        //     key: `ac`,
        //     label: <Link to={`/modp/ac`} >表现</Link>,
        // }
    ];
    return <Menu
        theme="dark"
        mode="horizontal "
        selectedKeys={keys}
        items={items}
    />
}

const MenuLeft = ({ colorBgContainer }) => {
    const path = useLocation();
    const pathSnippets = path.pathname.split('/').filter((i) => i); //remove any empty strings
    const app = pathSnippets[1];
    // const [location, setLocation] = useState(path);
    const [appMenu, setAppMenu] = useState([]);
    const p404 = CheckApp();
    const { makeRequest, menuData } = GlobleValues();
    useEffect(() => {
        if (!p404) {
            makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/menu/${app}`, null, setAppMenu, null)
        }
    }, [app]);
    if (p404) {
        return
    }

    const items = [];
    let selectKey = '';
    const openKeys = [];
    const menuCan = menuData.filter(e => appMenu.includes(e.value));
    menuCan.forEach(el => {
        let m = {
            key: el.path,
            // icon: React.createElement(UserOutlined),
        }
        if (el.children && el.children.length > 0) {
            // 有子菜单，label不是链接
            m.label = el.title
            // 有子菜单，需要展开
            openKeys.push(el.path)
            m.children = el.children.map(e => {
                // 子菜单是否选中
                if (path.pathname.endsWith(e.path) && selectKey.length < e.path.length) {
                    selectKey = e.path
                }
                return {
                    key: e.path,
                    label: <Link to={`/modp/${app}/${e.path}`} >{e.title}</Link>,
                }
            })
        } else {
            // 无子菜单，label是链接
            m.label = <Link to={`/modp/${app}/${el.path}`} >{el.title}</Link>
            // 菜单是否选中
            if (path.pathname.endsWith(el.path) && selectKey.length < el.path.length) {
                selectKey = el.path
            }
        }
        items.push(m)
    });

    return <Sider style={{
        background: colorBgContainer,
    }}
        breakpoint="lg"
        collapsedWidth="0"
    >
        <Menu
            mode="inline"
            items={items}
            selectedKeys={[selectKey]}
            openKeys={openKeys}
        />
    </Sider>
}

const CustomeBreadcrumb = () => {
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return { title: <Link to={url}>{pathSnippets[index]}</Link> };
    });
    const breadcrumbItems = [].concat(extraBreadcrumbItems);
    return <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
}

export default function Main() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Router>
            <Layout style={{
                minHeight: '100vh',
            }}>
                <Header>
                    <MenuTop />
                </Header>

                <Content className='ct-padding'>
                    <CustomeBreadcrumb />
                    <Layout
                        style={{
                            padding: '24px 0',
                            background: colorBgContainer,
                        }}
                    >
                        <MenuLeft colorBgContainer={colorBgContainer} />
                        <Content
                            style={{
                                padding: '0 24px',
                            }}
                        >
                            <Routes>
                                <Route path={`/modp`} element={<Apps />} />

                                <Route path={`/modp/:app/device`} element={<Device />} />

                                <Route path={`/modp/mapps`} element={<Mapps />} />
                                <Route path={`/modp/mapps/:id`} element={<Mapp />} />

                                <Route path={`/modp/:app/skad`} element={<Skad />} />
                                <Route path={`/modp/:app/`} element={<AppHome />} />
                                <Route path={`/modp/:app/data`} element={<Data />} />
                                <Route path={`/modp/:app/txt`} element={<Txt />} />
                                <Route path={`/modp/:app/res`} element={<Res />} />
                                <Route path={`/modp/:app/version/game`} element={<Version />} />
                                <Route path={`/modp/:app/version/res`} element={<VersionRes />} />
                                <Route path={`/modp/:app/ab`} element={<Ab />} />
                                <Route path={`/modp/:app/treasure`} element={<Treasure />} />
                                <Route path={`/modp/:app/event/def`} element={<EventDef />} />
                                <Route path={`/modp/:app/event/plan`} element={<EventPlan />} />


                                <Route path={`/modp/ip`} element={<></>} />
                                <Route path={`/modp/ac`} element={<Acquire />} />
                                <Route path={`/modp/skad`} element={<Skad />} />
                                <Route path={`/modp/cert`} element={<Cert />} />
                                <Route path={`/modp/oplog`} element={<Oplog />} />
                                <Route path={`/modp/menu`} element={<AppMenu />} />

                                <Route path="*" element={<P404 />} />
                            </Routes>

                        </Content>
                    </Layout>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    @{process.env.REACT_APP_GIT_REVISION}
                </Footer>
            </Layout>
        </Router>
    )
};
