import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Space, Tooltip, Spin } from 'antd';
import { useParams } from "react-router-dom";
import { CheckApp, GlobleValues } from './App';
import {
    FileUnknownTwoTone,
    ExclamationCircleOutlined
} from '@ant-design/icons';

const { TextArea } = Input;

const SearchForm = ({ queryList, clickAddResource, clickDelResource }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={24}>
            <Col xs={24} sm={24} md={18} lg={16} xl={12}>
                <Space>
                    <Form.Item name="gama_version">
                        <Input placeholder="版本" allowClear className='input1' />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button type="primary" onClick={clickAddResource}>
                                添加资源
                            </Button>
                            <Button type="dashed" danger onClick={clickDelResource}>
                                删除资源
                            </Button>
                        </Space>
                    </Form.Item>
                </Space>
            </Col>
            <Col xs={24} sm={24} md={6} lg={8} xl={12} style={{ textAlign: "right" }}>
                <Form.Item>
                    <Tooltip placement="right" title={"需开公司vpn访问"} arrow={true}>
                        <Button variant="filled" icon={<FileUnknownTwoTone />} href={`${process.env.REACT_APP_DOC_API_URL}/modp/update2`} target='_blank'>说明</Button>
                    </Tooltip>
                </Form.Item>
            </Col>
        </Row>
    </Form>
};

export default function VersionRes() {
    const { app } = useParams();
    const { makeRequest } = GlobleValues()
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const p404 = CheckApp();
    const [formAdd] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [formDelete] = Form.useForm();
    const [open2, setOpen2] = useState(false);
    const [ld2, setLd2] = useState(false);

    const queryList = useCallback((values) => {
        let gv = ''
        if (values['gama_version']) {
            gv = values['gama_version']
        }
        setLd(true);
        // 查询参数少，这里简单使用GET+参数到query参数的方式
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/resource?game_version=${gv}`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Config Res Version`;
            queryList({}, false);
        }
    }, [p404, queryList]);
    if (p404) {
        return p404;
    }

    const clickAddResource = () => {
        formAdd.setFieldsValue({
            doc: ''
        })
        setOpen(true);
    }

    const clickDelResource = () => {
        formDelete.setFieldsValue({
            Prefix: ''
        })
        setOpen2(true);
    }

    const okfn = () => {
        setOpen(false);
        setOpen2(false);
        queryList({})
    }

    const createResourceVer = (values) => {
        if (values.doc) {
            const doc = JSON.parse(values.doc)
            setLd(true);
            makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/resource`, doc, okfn, setLd);
        }
    };
    const deleteResourceVer = (values) => {
        if (values.Prefix) {
            setLd2(true)
            makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/resource`, values, okfn, setLd2);
        }
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'Id',
            width: 24,
        },
        {
            title: 'GameVersion',
            width: 30,
            dataIndex: 'GameVersion',
        },
        {
            title: 'InternalGameVersion',
            width: 36,
            dataIndex: 'InternalGameVersion',
        },
        {
            title: 'Platform',
            width: 24,
            dataIndex: 'Platform',
        },
        {
            title: 'UpdatePrefixUri',
            width: 36,
            dataIndex: 'UpdatePrefixUri',
        },
        {
            title: 'VersionListCompressedHashCode',
            width: 62,
            dataIndex: 'VersionListCompressedHashCode',
        },
        {
            title: 'VersionListCompressedLength',
            width: 62,
            dataIndex: 'VersionListCompressedLength',
        },
        {
            title: 'VersionListHashCode',
            width: 62,
            dataIndex: 'VersionListHashCode',
        },
        {
            title: 'VersionListLength',
            width: 62,
            dataIndex: 'VersionListLength',
        }
        // {
        //     title: '创建时间',
        //     width: 128,
        //     dataIndex: 'create_time',
        // },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAddResource={clickAddResource} clickDelResource={clickDelResource} />

            <Modal
                title="删除已上传的资源"
                icon={<ExclamationCircleOutlined />}
                open={open2}
                okText="删除"
                okType='danger'
                cancelText="取消"
                closable={true}
                onCancel={() => {
                    setOpen2(false);
                }}
                cancelButtonProps={{
                    disabled: ld2,
                }}
                confirmLoading={ld2}
                onOk={() => formDelete.submit()}
            >
                <Spin spinning={ld2}>
                    <Form
                        form={formDelete}
                        layout="vertical"
                        onFinish={deleteResourceVer}
                    >
                        <Form.Item
                            name="Prefix"
                            label='按前缀删除，谨慎操作！！！'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入要删除的前缀',
                                },
                            ]}>
                            <Input placeholder='要删除的文件前缀' />
                        </Form.Item>
                        <p>列表记录不会删除</p>
                        <p>仅为当不允许覆盖的情况下上传中断，从而导致无法继续上传的问题</p>
                    </Form>
                </Spin>
            </Modal>

            <Modal
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => formAdd.submit()}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={createResourceVer}
                >
                    <Form.Item
                        name="doc"
                        label="内容"
                        rules={[
                            {
                                required: true,
                                message: '请输入资源内容',
                            },
                        ]}>
                        <TextArea rows={10} />
                    </Form.Item>
                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.Id}
                loading={ld}
                scroll={{ y: '60vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
