import { useEffect } from 'react';
import { CheckApp } from './App';

export default function AppHome() {
  const p404 = CheckApp();
  useEffect(() => {
    if (!p404) {
      document.title = `AppHome`;
    }
  }, [p404]);
  return p404
}
