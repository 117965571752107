import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, message, Result, Button } from 'antd';
import { Link, useLocation } from "react-router-dom";
import Login from './Login';
import Main from './Main';

const menuData = [
  {
    title: '设备数据',
    value: '0-0',
    path: 'data'
  },
  {
    title: '配置版本',
    value: '0-100',
    path: 'txt'
  },
  {
    title: '资源版本',
    value: '0-200',
    path: 'res'
  },
  {
    title: '用户类型',
    value: '0-300',
    path: 'ab'
  },
  {
    title: '活动',
    value: '0-400',
    path: 'event',
    children: [
      {
        title: '活动值表',
        value: '0-400-0',
        path: 'event/def',
      },
      {
        title: '活动计划',
        value: '0-400-100',
        path: 'event/plan',
      }
    ],
  },
  {
    title: '资源',
    value: '0-500',
    path: 'version',
    children: [
      {
        title: '游戏版本',
        value: '0-500-0',
        path: 'version/game',
      },
      {
        title: '资源版本',
        value: '0-500-1',
        path: 'version/res',
      }
    ],
  },
  {
    title: '工会活动',
    value: '0-600',
    path: 'treasure'
  },
  {
    title: '设备管理',
    value: '0-700',
    path: 'device'
  },
];

const GlobleContext = createContext();

export const App = () => {

  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [apps, setApps] = useState(JSON.parse(sessionStorage.getItem('apps')));
  const [messageApi, contextHolder] = message.useMessage();

  const makeRequest = useCallback(async (method, url, data, okfn, ldfn) => {

    const clear = () => {
      setApps(null);
      sessionStorage.removeItem('apps');
      setToken(null);
      sessionStorage.removeItem('token');
      messageApi.open({
        type: 'error',
        content: '登录信息有误或失效',
      });
    }
    return await fetch(url, {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Auth': token
      },
      body: data ? JSON.stringify(data) : null
    })
      .then(response => response.json().then(dta => ({ s: response.status, d: dta })))
      .then(obj => {
        ldfn?.(false);
        if (obj.s === 200) {
          okfn?.(obj.d.data);
        } else if (obj.s === 401) {
          clear();
        } else {
          Modal.error({
            content: obj.d.err,
          });
        }
      })
      .catch((error) => {
        ldfn?.(false);
        Modal.error({
          content: error.message,
        });
      });
  }, [token, messageApi])

  const saveApps = useCallback((apps) => {
    apps.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    sessionStorage.setItem('apps', JSON.stringify(apps));
    setApps(apps);
  }, [setApps])

  const saveToken = useCallback((token) => {
    sessionStorage.setItem('token', token);
    setToken(token);
  }, [setToken])

  useEffect(() => {
    if (token) {
      makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/apps`, null, saveApps, null);
    }
  }, [token, makeRequest, saveApps]);

  return <>
    {contextHolder}
    <GlobleContext.Provider value={{ token, makeRequest, menuData, apps }}>
      {!token || !apps ?
        <Login setToken={saveToken} makeRequest={makeRequest} /> : <Main />
      }
    </GlobleContext.Provider>
  </>
};

export function GlobleValues() {
  return useContext(GlobleContext);
};

export function CheckApp() {
  const location = useLocation();
  const { apps } = useContext(GlobleContext);

  return useMemo(() => {
    console.log('checkapp')
    const allowedValues = apps.map(item => item.name);
    const pattern = new RegExp(`^/modp/(${allowedValues.join('|')})(/.*)?$`);
    return !pattern.test(location.pathname) ? <Result
      status="404"
      title="404"
      subTitle="抱歉，这个页面暂时不存在。"
      extra={<Button type="primary"><Link to={`/modp`} >Back Home</Link></Button>}
    /> : null
  }, [location, apps])
}
