
import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, Select, TreeSelect } from 'antd';
import { GlobleValues } from './App';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

export default function AppMenu() {
  const { menuData, makeRequest, apps } = GlobleValues();
  const [value, setValue] = useState([]);
  const [app, setApp] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [ld, setLd] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = `Menu`;
  }, []);

  const onChange = (newValue) => {
    setValue(newValue);
    setDisabled(false);
  };

  const saveOk = () => {
    setDisabled(true)
  }

  const saveData = () => {
    setLd(true)
    makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/menu/${app}`, value, saveOk, setLd)
  }

  const tProps = {
    treeDefaultExpandAll: true,
    treeData: menuData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    style: {
      width: '100%',
    },
  };
  return <Spin spinning={ld}>
    <Form
      layout='vertical'
      form={form}
    >
      <Form.Item label="应用">
        <Select
          placeholder="选择应用"
          onChange={v => {
            setApp(v);
            setValue([]);
            setDisabled(true)
            if (v) {
              setLd(true)
              makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/menu/${v}`, null, setValue, setLd)
            }
          }}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {apps.map(e => (
            <Option value={e.name} key={e.name}>{e.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="菜单">
        <TreeSelect {...tProps} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={saveData} disabled={disabled}>保存</Button>
      </Form.Item>
    </Form>
  </Spin>
};